import React from 'react'
import Reactions from './Reactions/Reactions';
import ReasonToRent from './ReasonToRent/ReasonToRent';
import StepsToBook from './StepsToBook/StepsToBook';
import Intro from './Intro/Intro';
import Feedback from './Feedback/Feedback';
import ExploreMore from './ExploreMore/ExploreMore';
import './Homepage.css'
import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import banner from '../../assets/banniere.jpg'
import { URL } from '../Variables'
import ReactGA from 'react-ga';
import { URL_Back } from '../Variables';
import BlogComponent from './OurBlog/Blog';
const Homepage = () => {
  const API_KEY = 'a2b18f9cfb72eb93f3ce6b1c30372b59';
  const API_URL = `${URL_Back}/lots/city`;
  const [lotData, setLotData] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const headers = {
          'apiKey': `${API_KEY}`,
        };

        const response = await fetch(`${API_URL}`, {
          method: 'GET',
          mode: 'cors',
          headers,
        });

        if (!isMounted) return;

        const responseData = await response.json();

        if (responseData) {
          setLotData(responseData);
        } else {
          console.error('Données inattendues de l\'API :', responseData);
          setLotData([]);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
        if (isMounted) {
          setLotData([]);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const headers = {
  //         'apiKey': `${API_KEY}`,
  //       };

  //       const response = await fetch(`${URL_Back}/homepage`, {
  //         method: 'GET',
  //         mode: 'cors',
  //         headers,
  //       });

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }

  //       const result = await response.json();
  //       if (
  //         result &&
  //         result.data &&
  //         result.data.page &&
  //         result.data.page.media &&
  //         result.data.page.media.length > 0
  //       ) {
  //         const originalUrl = result.data.page.media[0].original_url;
  //         if (originalUrl) {
  //           setData(originalUrl);
  //         } else {
  //           setData(banner);
  //         }
  //       } else {
  //         setData(banner);
  //       }

  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       setData(banner);
  //     }
  //   };

  //   fetchData();
  // }, []);



  return (
    <div id='Home' className='homepage-container'>
      <Helmet>
        <title>Landes Location</title>
        <meta
          name="description"
          content="Landes Location"
        />

        <link rel="canonical" href={`${URL}`} />
        <script type="application/ld+json">
          {`
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Landes Location",
      "url": "${URL}/",
      "logo": "/src/assets/logo.svg"
    }
  `}
        </script>

      </Helmet>
      <Intro backgroundImage={banner} />
      <Reactions></Reactions>
      <ExploreMore lotData={lotData} />
      <ReasonToRent />
      <StepsToBook />
      <BlogComponent numberToShow={3} showTitle={true} showButton={true}/>
      <Feedback />
    </div>
  )
}

export default Homepage;
