import React from 'react'
import BlogComponent from '../HomePage/OurBlog/Blog'
import './BlogPage.css'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { Helmet } from 'react-helmet'
import { URL } from '../Variables'
import { useTranslation } from 'react-i18next'

const BlogPage=()=> {
 
  return (
    <>
        <Helmet>
      <title>Blog</title>
      <meta
          name="description"
          content="Landes Location"
          />
          <link rel="canonical" href={`${URL}/contact`} />
          <script type="application/ld+json">
            {`
 {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Contact",
  "url": "${URL}/blog",
  "description": "page de contact"
}
`}
          </script>
    </Helmet>
    <Breadcrumbs
    path={`/blog`}/>
    <div className=' Blog-page mt-5'> 
    
        <h2>Latest News</h2>
        <p className='text-center mb-5'>Lorem Ipsum is simply dummy</p>
        <BlogComponent/>
    </div>
    </>
  )
}

export default BlogPage;