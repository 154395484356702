import React from 'react'
import "./Faq.css"
import arrow from "../../../assets/right-arrow 1.svg"
import { useTranslation } from 'react-i18next'

const Faq = () => {
  const {t,i18n}=useTranslation();
  const language=i18n.language ;
  const handleFaqButtonClick = () => {
    window.location.href=`/${language}/faq`
  };
  return (
    <> 
      <div className='Faq-container'> 
        <div className='container'>
          <h2>{t("faqFooter.titre1")}</h2>
          <p>{t("faqFooter.titre2")}</p>
        </div>
        <button className='Faq-btn' onClick={handleFaqButtonClick}>{t("faqFooter.button")}
          <img src={arrow} alt='icon'/>
        </button>
      </div>
    </>
  )
}
export default Faq
