import React from 'react';
import './CityResult.css';

const CityResult = ({ city }) => {
  const capitalizedCity = city.charAt(0).toUpperCase() + city.slice(1);
  
  let backgroundImage = null;
  try {
    backgroundImage = require(`../../../assets/${city}.png`);
  } catch (error) {
    console.warn(`Image not found for city: ${city}. Using default or no background.`);
  }

  const cityTexts = {
    nice: "Sunny boulevards, international allure, and Riviera charm",
    bologna: "A town of hidden gems",
    florence: "Lose yourself as you wander through the city's narrow lanes",
  };
  const cityDescription = cityTexts[city.toLowerCase()] || "";

  return (
    <div className='page-container-city' style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none', backgroundSize: 'cover' }}>
      <div className='container'>
        <div className='content-container-city'>
          <h1>{capitalizedCity}</h1>
          <p>{cityDescription}</p>
        </div>
      </div>
    </div>
  );
};

export default CityResult;
