import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconsTable } from "../../../Data/Data";
import "./Reactions.css";

const Reactions = () => {
  const { t } = useTranslation();

  return (
    <div className='reactions-block container mb-lg-5 mb-md-4 mb-sm-3 mb-0'>
      <div className="row py-2 g-4">
        {IconsTable.map((icon, index) => {
          
          const colClass = (index === 0 || index === 4) ? 'col-xl-3 col-lg-4 col-md-4 col-6' : 'col-xl-2  col-lg-4 col-md-4 col-6';
          const h3Class = (index === 3 || index === 4) ? 'special-size-h3' : '';

          return (
            <div key={index} className={colClass}>
              <div className='d-flex  align-items-center gap-2'>
                <img className='' src={icon.src} alt="icons" />
                <div className=''>
                  <h3 className={h3Class}>{t(`reactions.${icon.count}`)}</h3>
                  <p>{t(`reactions.${icon.content}`)}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Reactions;
