import React, { useEffect, useState } from 'react';
import './Blog.css';
import { blogPosts } from "../../../Data/Data";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { URL_Back } from '../../Variables';


const BlogComponent = ({ numberToShow, showTitle, showButton }) => {
    const API_KEY = 'a2b18f9cfb72eb93f3ce6b1c30372b59';
    const API_URL = `${URL_Back}/blogs`;
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const language = i18n.language;
    const [posts, setPosts] = useState();
    const navigate = useNavigate();


    const HandleBlogClick = (slug) => {
        navigate(`/${language}/blog-content/${slug}`);
        console.log(slug);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    'apiKey': `${API_KEY}`,
                };
                const response = await fetch(`${API_URL}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers,
                });
                const responseData = await response.json();
                if (responseData && responseData.data && responseData.data.response) {
                    const filteredPosts = responseData.data.response.filter(post =>
                        post.slug.startsWith(`${language}-`)
                    );
                    setPosts(filteredPosts);
                } else {
                    console.error('Unexpected data from API:', responseData);
                    setPosts([]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [language]);
    const formatDate = (dateString) => {
        if (!dateString) return null;

        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    };


    const postsToShow = posts && posts.length > 0
        ? (numberToShow ? posts.slice(0, numberToShow) : posts)
        : [];

    return (

        <div className='blog-section container'>
            {showTitle && <h2 className='mb-3'>{t("blog.titre")}</h2>}
            <div className="blog-cards-container mb-4">
                {postsToShow.map((post, index) => (
                    <div key={index} className="blog-card" onClick={() => HandleBlogClick(post.slug)}>
                        <img src={`https://admin.landes-location.com/blog_images/${post.image_medium}`} alt={post.title} className="blog-image img-fluid" />
                        <div className="blog-content">
                            <p className="blog-date">
                                <span role="img" aria-label="calendar">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.56445 1.6106C3.56445 1.38003 3.75137 1.19312 3.98194 1.19312H19.5816C19.8122 1.19312 19.9991 1.38003 19.9991 1.6106V17.1168C19.9991 17.3474 19.8122 17.5343 19.5816 17.5343H3.98194C3.75137 17.5343 3.56445 17.3474 3.56445 17.1168V14.7313C3.56445 14.5007 3.75137 14.3138 3.98194 14.3138C4.21251 14.3138 4.39942 14.5007 4.39942 14.7313V16.6993H19.1642V5.60592H3.98194C3.75137 5.60592 3.56445 5.41901 3.56445 5.18844V1.6106ZM19.1642 4.77095V2.02808H4.39942V4.77095H19.1642Z" fill="#00BFFF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.61377 0C7.84434 0 8.03126 0.186914 8.03126 0.417484V2.80549C8.03126 3.03606 7.84434 3.22297 7.61377 3.22297C7.3832 3.22297 7.19629 3.03606 7.19629 2.80549V0.417484C7.19629 0.186914 7.3832 0 7.61377 0Z" fill="#00BFFF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.0005 0C16.2311 0 16.418 0.186914 16.418 0.417484V2.80549C16.418 3.03606 16.2311 3.22297 16.0005 3.22297C15.7699 3.22297 15.583 3.03606 15.583 2.80549V0.417484C15.583 0.186914 15.7699 0 16.0005 0Z" fill="#00BFFF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.7886 0C12.0191 0 12.2061 0.186914 12.2061 0.417484V2.80549C12.2061 3.03606 12.0191 3.22297 11.7886 3.22297C11.558 3.22297 11.3711 3.03606 11.3711 2.80549V0.417484C11.3711 0.186914 11.558 0 11.7886 0Z" fill="#00BFFF" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.99618 4.76099C4.22675 4.76099 4.41366 4.9479 4.41366 5.17847C4.41366 8.54122 3.49639 10.9997 2.5694 12.6234C2.15194 13.3546 1.7333 13.9151 1.39718 14.3122H15.8426C15.8506 14.3046 15.8589 14.2966 15.8677 14.2882C15.9578 14.2012 16.0895 14.0673 16.2492 13.8853C16.5685 13.5214 16.9988 12.9657 17.4306 12.2094C18.293 10.699 19.165 8.38191 19.165 5.17847C19.165 4.9479 19.3519 4.76099 19.5825 4.76099C19.8131 4.76099 20 4.9479 20 5.17847C20 8.54122 19.0827 10.9997 18.1557 12.6234C17.6929 13.4341 17.2285 14.0351 16.8768 14.436C16.701 14.6365 16.5531 14.787 16.4475 14.889C16.3947 14.9399 16.3525 14.9788 16.3225 15.0056C16.3075 15.019 16.2956 15.0294 16.287 15.0369L16.2766 15.0458L16.2733 15.0486L16.2721 15.0496C16.2719 15.0497 16.2713 15.0503 16.0038 14.7297L16.2713 15.0503C16.1962 15.1129 16.1016 15.1471 16.0038 15.1471H0.417505C0.241771 15.1471 0.0848518 15.0371 0.0249896 14.8719C-0.0347705 14.7069 0.0149703 14.5223 0.149402 14.4096L0.150092 14.4091L0.154883 14.4049C0.159812 14.4007 0.167999 14.3935 0.179227 14.3835C0.201686 14.3634 0.236301 14.3317 0.281349 14.2882C0.371456 14.2012 0.503207 14.0673 0.662836 13.8853C0.982149 13.5214 1.41249 12.9657 1.84429 12.2094C2.70663 10.699 3.57869 8.38191 3.57869 5.17847C3.57869 4.9479 3.76561 4.76099 3.99618 4.76099Z" fill="#00BFFF" />
                                    </svg>
                                </span>
                                {formatDate(post.posted_at)}
                            </p>
                            <h5 className="blog-title">{post.title}</h5>
                            <p className="blog-description">{post.short_description}</p>
                        </div>
                    </div>
                ))}
            </div>
            {showButton && <button className="visit-blog-button mb-5" onClick={() => window.location.href = `/${language}/blog`}>
                {t("blog.visit")}
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="15" viewBox="0 0 30 15" fill="none">
                        <g clip-path="url(#clip0_37_26)">
                            <path d="M29.5083 7.03685L22.8129 0.467923C22.753 0.398724 22.6791 0.342456 22.5959 0.302668C22.5127 0.26288 22.422 0.240434 22.3295 0.236748C22.2371 0.233062 22.1448 0.248213 22.0586 0.281249C21.9724 0.314284 21.8942 0.364488 21.8287 0.42869C21.7633 0.492892 21.7121 0.569698 21.6784 0.65427C21.6448 0.738842 21.6293 0.829343 21.6331 0.920063C21.6368 1.01078 21.6597 1.09975 21.7003 1.18136C21.7408 1.26298 21.7982 1.33547 21.8687 1.39425L27.4193 6.84663H0.911934C0.73882 6.85309 0.574991 6.92511 0.454844 7.04756C0.334698 7.17001 0.267578 7.33337 0.267578 7.50333C0.267578 7.6733 0.334698 7.83666 0.454844 7.95911C0.574991 8.08156 0.73882 8.15357 0.911934 8.16004H27.4193L21.8687 13.6058C21.7982 13.6645 21.7408 13.737 21.7003 13.8186C21.6597 13.9003 21.6368 13.9892 21.6331 14.0799C21.6293 14.1707 21.6448 14.2612 21.6784 14.3457C21.7121 14.4303 21.7633 14.5071 21.8287 14.5713C21.8942 14.6355 21.9724 14.6857 22.0586 14.7188C22.1448 14.7518 22.2371 14.7669 22.3295 14.7633C22.422 14.7596 22.5127 14.7371 22.5959 14.6973C22.6791 14.6575 22.753 14.6013 22.8129 14.5321L29.5083 7.96317C29.633 7.84005 29.7029 7.67355 29.7029 7.50001C29.7029 7.32646 29.633 7.15997 29.5083 7.03685Z" fill="white" stroke="white" stroke-width="0.5" />
                            <path d="M29.2407 6.80064L22.5453 0.231717C22.4854 0.162517 22.4115 0.10625 22.3283 0.0664622C22.2451 0.0266741 22.1544 0.00422816 22.062 0.000541903C21.9695 -0.00314435 21.8773 0.0120072 21.7911 0.0450427C21.7049 0.0780782 21.6266 0.128282 21.5611 0.192484C21.4957 0.256686 21.4445 0.333492 21.4109 0.418064C21.3772 0.502636 21.3617 0.593137 21.3655 0.683857C21.3693 0.774577 21.3921 0.863545 21.4327 0.945158C21.4732 1.02677 21.5306 1.09927 21.6011 1.15805L27.1517 6.61042H0.644356C0.471242 6.61688 0.307413 6.6889 0.187266 6.81135C0.0671194 6.9338 0 7.09716 0 7.26713C0 7.43709 0.0671194 7.60045 0.187266 7.7229C0.307413 7.84535 0.471242 7.91737 0.644356 7.92383H27.1517L21.6011 13.3696C21.5306 13.4283 21.4732 13.5008 21.4327 13.5824C21.3921 13.664 21.3693 13.753 21.3655 13.8437C21.3617 13.9345 21.3772 14.025 21.4109 14.1095C21.4445 14.1941 21.4957 14.2709 21.5611 14.3351C21.6266 14.3993 21.7049 14.4495 21.7911 14.4825C21.8773 14.5156 21.9695 14.5307 22.062 14.5271C22.1544 14.5234 22.2451 14.5009 22.3283 14.4611C22.4115 14.4213 22.4854 14.3651 22.5453 14.2959L29.2407 7.72696C29.3654 7.60385 29.4353 7.43735 29.4353 7.2638C29.4353 7.09026 29.3654 6.92376 29.2407 6.80064Z" fill="white" stroke="white" stroke-width="0.5" />
                        </g>
                        <defs>
                            <clipPath id="clip0_37_26">
                                <rect width="30" height="15" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </span></button>}
        </div>
    );
};

export default BlogComponent;
