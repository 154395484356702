import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18next';

const initializeLanguage = () => {
  if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'fr'); 
  }
};
initializeLanguage();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <App />
 
  </React.StrictMode>
);

